import { FC, useCallback, useMemo, useState }       from "react";
import { useMatomo }                                from "@datapunt/matomo-tracker-react";
import useBreakpoint                                from "antd/lib/grid/hooks/useBreakpoint";
import clsx                                         from "clsx";
import { useResizeDetector }                        from "react-resize-detector/build/withPolyfill";
import { useLocation }                              from "react-router-dom";
import { animated, useSpring }                      from "react-spring";
import { getAnalyticsCategory }                     from "@/analytics/analytics";
import { UserProfileAvatar }                        from "@/components/feature/auth/UserProfileAvatar/UserProfileAvatar";
import { ChristmasShopAppBarHeader }                from "@/components/feature/shopUSA/christmas/ChristmasShopAppBarHeader/ChristmasShopAppBarHeader";
import { DISPLAY_OUTAGE_BANNER, HIDE_AUTH_BUTTONS } from "@/config";
import { useSearchTabsVisibilityContext }           from "@/context/useSearchTabsVisibilityContext";
import Link                                         from "@/designSystem/Link/Link";
import { getRedirectURL }                           from "@/helpers/index";
import { HINT_TYPES, StorageService }               from "@/helpers/storage";
import { useAppBar }                                from "@/hooks/useAppBar";
import { useAuth }                                  from "@/hooks/useAuth";
import { Election2024Banner }                       from "@/molecules/Election2024Banner/Election2024Banner";
import Hamburger                                    from "@/molecules/Hamburger/Hamburger";
import { OutageBanner }                             from "@/molecules/OutageBanner/OutageBanner";
import SearchBar                                    from "@/molecules/SearchBar/SearchBar";
import { DynamicCTA }                               from "@/organisms/DynamicCTA/DynamicCTA";
import { ReactComponent as FreespokeLogoMobile }    from "@/static/icons/freespoke_torch_brand_color.svg";
import "./AppBarHeader.less";

// import { ReactComponent as FreespokeLogoDesktop } from "@/static/logo/logo.svg";
// import useBreakpoint                      from "antd/lib/grid/hooks/useBreakpoint";

// Regular Theme
// import { ReactComponent as FreespokeLogoDesktop } from "@/static/logo/logo.svg";
// import { ReactComponent as FreespokeLogoMobile }  from "@/static/logo/logo_small.svg";
// Winter Theme
// import { ReactComponent as FreespokeLogoDesktop } from "@/static/logo/seasonal/winter_logo.svg";
// import { ReactComponent as FreespokeLogoMobile }  from "@/static/logo/seasonal/winter_logo_small.svg";

interface AppBarHeaderProps {
    className?: string;
}

const AppBarHeader: FC<React.PropsWithChildren<AppBarHeaderProps>> = ({ className }) => {
    const { tabsAreVisible } = useSearchTabsVisibilityContext();
    const { showAppBar, closeHamburger, showHamburger } = useAppBar();
    const location = useLocation();
    const redirectURL = getRedirectURL(location);
    const { trackEvent } = useMatomo();
    const { ref } = useAppBarResize();
    const { md, lg } = useBreakpoint();
    const { isAuthenticated } = useAuth();
    const pageName = getAnalyticsCategory(location.pathname);

    const hasAccount = useMemo(() => StorageService.HasHint.get(HINT_TYPES.HAS_ACCOUNT), []);

    const [isSearchBarFocused, setIsSearchBarFocused] = useState<boolean>(false);

    const isFreespokeMobileApp = useMemo(() => navigator.userAgent.toLowerCase().includes("freespoke"), []);

    const isSearch = useMemo(
        () => location.pathname.startsWith("/search") || location.pathname.startsWith("/about") || location.pathname.startsWith("/shop"),
        [location.pathname]
    );

    const showBorder = (isSearch && tabsAreVisible === false) || location.pathname.startsWith("/about");

    const headerSpring = useSpring({
        ...(showAppBar || isFreespokeMobileApp
            ? { transform: "translateY(0%)", opacity: 1, height: "auto" }
            : { transform: "translateY(-100%)", opacity: 0, height: 0 }),
    });

    const recordEvent = useCallback(() => {
        // Matomo analytics click logo back to the homepage
        trackEvent({
            category: "nav bar",
            action: "nav bar logo click",
            name: "click",
        });
    }, [trackEvent]);

    const loginButtonClick = useCallback(() => {
        trackEvent({
            category: "nav bar",
            action: `${pageName} - nav bar login cta click `,
            name: "click",
        });
    }, [pageName, trackEvent]);

    const handleDynamicCTAClick = useCallback(
        (type: string) => {
            if (type.includes("Firefox") || type.includes("Brave") || type.includes("Edge") || type.includes("Chrome")) {
                trackEvent({
                    category: "nav bar",
                    action: `${pageName} - nav bar browser extension cta click`,
                    name: "click",
                });
            } else {
                trackEvent({
                    category: "nav bar",
                    action: `${pageName} - nav bar ${type} cta click`,
                    name: "click",
                });
            }
        },
        [pageName, trackEvent]
    );

    const isAboutPage =
        location.pathname.startsWith("/about-freespoke") ||
        location.pathname.startsWith("/darkhorse") ||
        location.pathname.startsWith("/about") ||
        location.pathname.startsWith("/privacyfiles");

    const renderHeaderLinks = useMemo(() => {
        if (md === false) {
            if (isFreespokeMobileApp) {
                return null;
            }

            return <DynamicCTA className="dynamic-cta" excludedTypes={["sign-up", "log-in"]} />;
        }

        return (
            <>
                {isAboutPage ? (
                    <div className="link-group">
                        <Link className="header-link" to="/election/2024">
                            Election
                        </Link>
                        <Link className="header-link" to="/news">
                            News
                        </Link>
                        <Link className="header-link" to="/shop">
                            Shop
                        </Link>
                    </div>
                ) : (
                    <>
                        {!HIDE_AUTH_BUTTONS && lg && !isAuthenticated && (
                            <>
                                {hasAccount && !DISPLAY_OUTAGE_BANNER && (
                                    <Link
                                        className="header-link"
                                        data-testid="header-login-link"
                                        to={{
                                            pathname: "/login",
                                            search: `?redirect_uri=${redirectURL}`,
                                        }}
                                        onClick={loginButtonClick}
                                    >
                                        Log In
                                    </Link>
                                )}
                                {!hasAccount && (
                                    <Link className="header-link" to={"/signup"} data-testid="app-header-signup-link">
                                        Sign Up
                                    </Link>
                                )}
                            </>
                        )}
                    </>
                )}
                <>
                    <DynamicCTA className="dynamic-cta" excludedTypes={["sign-up", "log-in"]} onClickFunc={handleDynamicCTAClick} />
                    <UserProfileAvatar className="logo-icon" />
                </>
            </>
        );
    }, [handleDynamicCTAClick, hasAccount, isAboutPage, isAuthenticated, isFreespokeMobileApp, lg, loginButtonClick, md, redirectURL]);

    const onLogoClick = useCallback(() => {
        if (location.pathname === "/") {
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
        }
        closeHamburger?.();
    }, [closeHamburger, location.pathname]);

    if (location.pathname.startsWith("/_components/preview")) {
        return null;
    }

    if (location.pathname.startsWith("/shop")) {
        return <ChristmasShopAppBarHeader />;
    }

    return (
        <>
            <animated.header
                className={clsx("AppBarHeader", className, isSearch && !showHamburger && "search-theme", showBorder && "show-border")}
                ref={ref}
                style={headerSpring}
            >
                {!DISPLAY_OUTAGE_BANNER && <Election2024Banner />}
                {DISPLAY_OUTAGE_BANNER && <OutageBanner />}
                <div className={clsx("wrapper", !isSearch && "max-wrap", !showAppBar && "hidden")}>
                    <div className="left-section">
                        <Link to="/" className="logo" onClick={onLogoClick}>
                            {/* {lg ? <FreespokeLogoDesktop onClick={recordEvent} /> : <FreespokeLogoMobile onClick={recordEvent} />} */}
                            <FreespokeLogoMobile onClick={recordEvent} className="freespoke-logo" />
                        </Link>
                        <SearchBar
                            className={clsx("search-bar", isSearchBarFocused && "active")}
                            parent="header"
                            placeholder={md ? "Anonymously search the web..." : "Try a search..."}
                            setIsSearchBarFocused={setIsSearchBarFocused}
                        />
                    </div>

                    {(lg || (lg === false && !isSearchBarFocused)) && (
                        <div className="right-section">
                            {renderHeaderLinks}

                            <Hamburger
                                analyticsMeta={{
                                    category: "nav bar",
                                    name: "click",
                                    action: "nav bar hamburger menu open / close",
                                }}
                            />
                        </div>
                    )}
                </div>

                {/* Winter Theme */}
                {/* <Snowflakes isVisible={!!showAppBar} /> */}
            </animated.header>
        </>
    );
};

export default AppBarHeader;

const useAppBarResize = () => {
    const onResize = useCallback((_width?: number, height?: number) => {
        const doc = document.documentElement;
        doc.style.setProperty("--app-header-height", `${height || 0}px`);
    }, []);

    // A Hook that listens on the outer HTML element height change
    const { ref } = useResizeDetector({
        handleHeight: true,
        handleWidth: false,
        refreshMode: "debounce",
        refreshRate: 50,
        onResize,
    });

    return { ref };
};
