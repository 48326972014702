export const CONSTANTS = {
    LINKS: {
        IOS_APP: "https://apps.apple.com/us/app/freespoke/id1617332602",
        ANDROID_APP: "https://play.google.com/store/search?q=freespoke&c=apps",
        IOS_APP_DIRECT: "https://apps.apple.com/us/app/freespoke/id1617332602",
        ANDROID_APP_DIRECT: "https://play.google.com/store/apps/details?id=com.freespoke&hl=en_US",
        CHROME_EXTENSION: "https://freespoke.app.link/4mBHwJgdsCb",
        FIREFOX_EXTENSION: "https://freespoke.app.link/8CDVFEadsCb",
        CHROME_EXTENSION_HOW_TO_INSTALL_MODAL: "#how-to-get-chrome-extension",
        FIREFOX_EXTENSION_HOW_TO_INSTALL_MODAL: "#how-to-get-firefox-extension",
        BRAVE_EXTENSION_HOW_TO_INSTALL_MODAL: "#how-to-get-brave-extension",
        EDGE_EXTENSION_HOW_TO_INSTALL_MODAL: "#how-to-get-edge-extension",
        FRESHDESK_NEW_TICKET: "https://freespoke-support.freshdesk.com/support/tickets/new",
        BLOG: "https://freespoke.substack.com",
        TWITTER: "https://twitter.com/FreespokeSearch",
        FACEBOOK: "https://www.facebook.com/FreespokeSearch",
        INSTAGRAM: "https://www.instagram.com/freespokesearch/",
        LINKED_IN: "https://www.linkedin.com/company/freespoke-search/",
        YOUTUBE: "https://www.youtube.com/@freespoke",
        SPANGLD: "https://spangld.com",
        ACCOUNT: {
            LOGIN: "/login",
            SIGNUP: "/signup",
            PROFILE: "/account/profile",
            ADVANCED_FILTERS: "/account/profile/filters",
        },
        PREMIUM: {
            PREMIUM: "/premium",
            SUBSCRIBE: "/premium/subscribe",
            APP: "/premium/app",
        },
        SHOP_USA_PARTNER_LINK: "https://about.freespoke.com/ShopTeam",
    },
    // countries that use 12 hour time format
    COUNTRIES: {
        USE_12H_TIME_FORMAT: new Set([
            "US", // "United States of America",
            "GB", // "United Kingdom",
            "CA", // "Canada"
            "AU", // "Australia"
            "IN", // "India"
            "PK", // "Pakistan"
            "PH", // "Philippines"
            "EG", // "Egypt"
            "SA", // "Saudi Arabia"
            "CO", // "Colombia"
            "MX", // "Mexico"
            "NZ", // "New Zealand"
            "MY", // "Malaysia"
            "SG", // "Singapore"
            "BI", // "Burundi"
            "KE", // "Kenya"
            "UG", // "Uganda"
            "NP", // "Nepal"
            "ZA", // "South Africa"
            "BD", // "Bangladesh"
            "IE", // "Ireland"
        ]),
        USE_FAHRENHEIT: new Set([
            "US", //"United States of America"
            "AS", //"American Samoa"
            "AG", //"Antigua and Barbuda"
            "VG", //"British Virgin Islands"
            "GU", //"Guam"
            "LR", //"Liberia"
            "MH", //"Marshall Islands"
            "FM", //"Federated States of Micronesia"
            "MS", //"Montserrat"
            "MP", //"Northern Mariana Islands"
            "PR", //"Puerto Rico"
            "KN", //"Saint Kitts and Nevis"
            "TC", //"Turks and Caicos Islands"
            "VI", //"US Virgin Islands"
            "BS", //"Bahamas"
            "BZ", //"Belize"
            "KY", //"Cayman Islands"
            "PW", //"Palau"
        ]),
    },
} as const;
