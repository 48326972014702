import { FC, useCallback, useEffect, useState }       from "react";
import useBreakpoint                                  from "antd/lib/grid/hooks/useBreakpoint";
import clsx                                           from "clsx";
import { isSameDay, isWithinInterval, parseISO, set } from "date-fns";
import { utcToZonedTime }                             from "date-fns-tz";
import { useLocation }                                from "react-router-dom";
import { getAnalyticsCategory }                       from "@/analytics/analytics";
import { ENABLE_ELECTION_BANNER }                     from "@/config";
import Link                                           from "@/designSystem/Link/Link";
import { DISMISSIBLE_COMPONENTS, StorageService }     from "@/helpers/storage";
import { useAnalyticsTracking }                       from "@/hooks/useAnalyticsTracking";
import { ReactComponent as CloseIcon }                from "@/static/icons/close.svg";
import "./Election2024Banner.less";

interface Election2024BannerProps {
    className?: string;
}

const LOCAL_STORAGE_VALUE = DISMISSIBLE_COMPONENTS.election_2024_trump_harris_debate;

export const Election2024Banner: FC<React.PropsWithChildren<Election2024BannerProps>> = ({ className }) => {
    const location = useLocation();
    const { sm } = useBreakpoint();
    const { trackEvent } = useAnalyticsTracking();
    const pageName = getAnalyticsCategory(location.pathname);

    useEffect(() => {
        const timeout = setTimeout(() => {
            trackEvent({
                category: "toast",
                action: `${pageName} toast banner view`,
                name: "view",
            });
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = useCallback(
        () =>
            trackEvent({
                category: "toast",
                action: `${pageName} toast banner click for live coverage click`,
                name: "click",
            }),
        [pageName, trackEvent]
    );

    // NOTE: we have to read the storage on every render, otherwise when user dismissed it might be still displayed on other components (such app bar header)
    const hasDismissed = StorageService.HasDismissedComponentWithinSession.get(LOCAL_STORAGE_VALUE);
    const [showElectionPopup, setShowElectionPopup] = useState<boolean>(!hasDismissed);

    const onElectionBannerDismiss = useCallback(() => {
        setShowElectionPopup(false);
        StorageService.HasDismissedComponentWithinSession.set(LOCAL_STORAGE_VALUE, true);
    }, []);

    const isElectionPage = location.pathname.startsWith("/election/2024");

    if (!ENABLE_ELECTION_BANNER || hasDismissed || !showElectionPopup || isElectionPage || !shouldRenderBanner()) return null;

    return (
        <div className={clsx("Election2024Banner", className && className)}>
            <Link to="/election/2024" className="custom-cta" onClick={handleClick}>
                <span className="emoji">📺</span>
                <span className="description inline-block">
                    {sm ? <>Donald Trump and Kamala Harris Debate for the First Time. </> : <>Trump and Harris Debate. </>}
                </span>
                <span className="code inline-block">{sm ? "Click for Live Coverage." : "View Now."}</span>
            </Link>
            <button aria-label="Close" className="close-banner" onClick={onElectionBannerDismiss}>
                <CloseIcon onClick={onElectionBannerDismiss} />
            </button>
        </div>
    );
};

function shouldRenderBanner() {
    // Define the Eastern Time Zone
    const timeZone = "America/New_York";

    // Get the current time in Eastern Time Zone
    const now = new Date();
    const currentEasternTime = utcToZonedTime(now, timeZone);

    // Define the specific date for visibility: September 10, 2024
    const targetDate = parseISO("2024-09-10T00:00:00");

    // Check if the current day is the target date
    const isTargetDate = isSameDay(currentEasternTime, targetDate);

    if (!isTargetDate) {
        return false; // Do not render if it's not the target date
    }

    // Define start and end times for visibility on the target date
    const startVisibility = set(currentEasternTime, { hours: 18, minutes: 0, seconds: 0 }); // 6:00 PM
    const endVisibility = set(currentEasternTime, { hours: 23, minutes: 59, seconds: 59 }); // 11:59 PM

    // Check if the current time is within the visibility window on the target date
    return isWithinInterval(currentEasternTime, { start: startVisibility, end: endVisibility });
}
