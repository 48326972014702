import { z }       from "zod";
import { Geocode } from "@/@types/custom";
import { uuidv4 }  from "./index";

export interface UserLocation {
    source: string;
    name: string;
    geocode: Geocode;
    timestamp: number; // Unix time in milliseconds
}

const GeocodeSchema = z
    .object({
        latitude: z.number().min(-90).max(90),
        longitude: z.number().min(-180).max(180),
    })
    .optional();

/*
 * Location context contains user location information, such as lat, lon, city etc.
 * This information gets sent in the API requests that serve location specific results. Example when user searches "pizza near me".
 * This context only sets when user clicks on "Use my location" button or types location manually. See 'LocationContextDisplay' component.
 */
const LocationContext = {
    getKey: () => "--app:location_context",
    get() {
        const item = localStorage.getItem(this.getKey());
        if (!item) {
            return null;
        }
        const { geocode, ...location } = JSON.parse(item) as UserLocation;

        const validationResult = GeocodeSchema.safeParse(geocode);

        const validatedGeocode = validationResult?.success ? validationResult?.data : undefined;

        // This means that the geocode has invalid format thus to prevent API errors we delete it.
        if (!validationResult.success) {
            console.error("LocationContext error: ", validationResult.error);
            this.delete();
        }

        return { ...location, geocode: validatedGeocode };
    },
    set(userLocation: PartialBy<UserLocation, "timestamp">) {
        const timeStampedLocation = { timestamp: Date.now(), ...userLocation };
        localStorage.setItem(this.getKey(), JSON.stringify(timeStampedLocation));
    },
    delete() {
        localStorage.removeItem(this.getKey());
    },
};

/*
 * ClientId is used with search related API calls. It is created and provided by the server.
 * NOTE: it should not be created on the client side.
 */
const ClientId = {
    getKey: () => "--app:client_id",
    get() {
        const item = sessionStorage.getItem(this.getKey());
        return item;
    },
    set(id: string) {
        sessionStorage.setItem(this.getKey(), id);
    },
    delete() {
        sessionStorage.removeItem(this.getKey());
    },
};

/*
 * SessionToken is a unique ID created by the client.
 * Currently it is used for fetching guest JWT token.
 */
const SessionToken = {
    getKey: () => "--app:session_token",
    get() {
        const sessionToken = localStorage.getItem(this.getKey());
        // if there is no sessionToken - let's create one
        if (!sessionToken) {
            return this.set();
        }
        return sessionToken;
    },
    set() {
        const sessionId: string = uuidv4();
        localStorage.setItem(this.getKey(), sessionId);
        return sessionId;
    },
    delete() {
        localStorage.removeItem(this.getKey());
    },
};

/*
 * TypeaheadToken is a unique alpha numeric ID that is created by the server. Used in the context of FSQ session_token
 * Currently it is used for typeahead related API calls and /search/web API calls
 */
const TypeaheadToken = {
    getKey: () => "--app:typeahead_token",
    get() {
        return localStorage.getItem(this.getKey());
    },
    set(id: string) {
        localStorage.setItem(this.getKey(), id);
    },
    delete() {
        localStorage.removeItem(this.getKey());
    },
};

/*
 * HasPlayedIcyHomepageAnimation is boolean that gets set to true whenever a user visits the Icy Homepage on a non-private browser session.
 * It is used to prevent the iceberg animation from playing for returning users.
 */
const HasPlayedIcyHomepageAnimation = {
    getKey: () => "--app:icy_homepage_animation",
    get() {
        return localStorage.getItem(this.getKey()) === "true";
    },
    set(hasPlayed: boolean) {
        localStorage.setItem(this.getKey(), hasPlayed.toString());
    },
    delete() {
        localStorage.removeItem(this.getKey());
    },
};

/*
 * HasShownAddFreePopup is boolean that gets set to true whenever ad-free popup is shown to a user
 * It is used to prevent the popup from appearing for returning users.
 */
const HasShownAddFreePopup = {
    getKey: () => "--app:shown_ad_free_popup",
    get() {
        return localStorage.getItem(this.getKey()) === "true";
    },
    set(hasShown: boolean) {
        localStorage.setItem(this.getKey(), hasShown.toString());
    },
    delete() {
        localStorage.removeItem(this.getKey());
    },
};

/*
 * Stores marketing campaign id when user opens the site via marketing link.
 * Example: https://freespoke.com?mtm_campaign=raaf_top_story_traffic_676
 * It is used to change the content of "Download Freespoke" popup
 */
const MarketingCampaignId = {
    getKey: () => "--app:mtm_campaign",
    get() {
        return sessionStorage.getItem(this.getKey());
    },
    set(id: string) {
        sessionStorage.setItem(this.getKey(), id);
    },
    delete() {
        sessionStorage.removeItem(this.getKey());
    },
};

const HasShownAppOrExtensionPopup = {
    getKey: () => "--app:app_or_extension_pop_up",
    get() {
        return localStorage.getItem(this.getKey()) === "true";
    },
    set(hasAppeared: boolean) {
        localStorage.setItem(this.getKey(), hasAppeared.toString());
    },
    delete() {
        localStorage.removeItem(this.getKey());
    },
};

// Used for the story page newsletter signup drawer
const HasShownStoryPageSignupDrawer = {
    getKey: () => "--app:story_page_signup_drawer",
    get() {
        return localStorage.getItem(this.getKey()) === "true";
    },
    set(hasAppeared: boolean) {
        localStorage.setItem(this.getKey(), hasAppeared.toString());
    },
    delete() {
        localStorage.removeItem(this.getKey());
    },
};

// Used for the shop USA newsletter signup drawer
const HasShownShopUsaSignupDrawer = {
    getKey: () => "--app:shop_usa_signup_drawer",
    get() {
        return localStorage.getItem(this.getKey()) === "true";
    },
    set(hasAppeared: boolean) {
        localStorage.setItem(this.getKey(), hasAppeared.toString());
    },
    delete() {
        localStorage.removeItem(this.getKey());
    },
};

const HasShownCampaignPopup = {
    getKey: (campaignId: string) => `--app:campaign_${campaignId}_popup`,
    get(campaignId: string) {
        return sessionStorage.getItem(this.getKey(campaignId)) === "true";
    },
    set(campaignId: string, hasAppeared: boolean) {
        sessionStorage.setItem(this.getKey(campaignId), hasAppeared.toString());
    },
    delete(campaignId: string) {
        sessionStorage.removeItem(this.getKey(campaignId));
    },
};

const HasShownPopupLocalStorage = {
    getKey: (campaignId: string) => `--app:local_${campaignId}_popup`,
    get(campaignId: string) {
        return localStorage.getItem(this.getKey(campaignId)) === "true";
    },
    set(campaignId: string, hasAppeared: boolean) {
        localStorage.setItem(this.getKey(campaignId), hasAppeared.toString());
    },
    delete(campaignId: string) {
        localStorage.removeItem(this.getKey(campaignId));
    },
};

const DarkModePreference = {
    getKey: () => "--app:dark_mode_preference",
    get() {
        return localStorage.getItem(this.getKey());
    },
    set(isDarkMode: boolean) {
        localStorage.setItem(this.getKey(), isDarkMode.toString());
    },
    delete() {
        localStorage.removeItem(this.getKey());
    },
};

/**
 * This is used to track the dismissal of a custom notification that appears on the homepage.
 * Mere existence of a k/v pair is used to determine whether the notification was dismissed or not.
 */
const CustomMessageDismissal = {
    getKey: (keyBase: string) => `--app:custom_message_hide_${keyBase}`,
    get(keyBase: string) {
        return !!localStorage.getItem(this.getKey(keyBase));
    },
    set(keyBase: string) {
        localStorage.setItem(this.getKey(keyBase), "hidden");
    },
    delete(keyBase: string) {
        localStorage.removeItem(this.getKey(keyBase));
    },
};

/**
 * This is used to track the dismissal of the "Discover Shop USA CTA" that appears on the homepage.
 */
const HomepageShopCTADismissal = {
    getKey: () => "--app:homepage_shop_cta_dismiss",
    get(): boolean {
        return sessionStorage.getItem(this.getKey()) === "true";
    },
    set(shouldDismiss: boolean) {
        sessionStorage.setItem(this.getKey(), shouldDismiss.toString());
    },
    delete() {
        sessionStorage.removeItem(this.getKey());
    },
};

/**
 * This is used to track which page a user lands on during a session (matomo event land001)
 */
const HasLandedOnFreeSpoke = {
    getKey: () => "--app:has_landed",
    get(): boolean {
        return sessionStorage.getItem(this.getKey()) === "true";
    },
    set(hasLanded: boolean) {
        sessionStorage.setItem(this.getKey(), hasLanded.toString());
    },
    delete() {
        sessionStorage.removeItem(this.getKey());
    },
};

/**
 * This is used to store whether a user has installed our browser extension and it's enabled
 */
const HasFreespokeExtensionEnabled = {
    getKey: () => "--app:freespoke_extension_enabled",
    get(): boolean {
        return localStorage.getItem(this.getKey()) === "true";
    },
    set(hasExtensionEnabled: boolean) {
        localStorage.setItem(this.getKey(), hasExtensionEnabled.toString());
    },
    delete() {
        localStorage.removeItem(this.getKey());
    },
};

// This is used to track whether a user has dismissed a component (localStorage)
export enum DISMISSIBLE_COMPONENTS {
    election_2024_primaries_header_banner = "election_2024_primaries_header_banner",
    election_2024_rnc_banner = "election_2024_rnc_banner",
    search_controls_banner = "search_controls_banner",
    election_2024_trump_harris_debate = "election_2024_trump_harris_debate",
}
const HasDismissedComponent = {
    getKey: (component_name: DISMISSIBLE_COMPONENTS) => `--app:comp_name:${component_name}:dismissed`,
    get(component_name: DISMISSIBLE_COMPONENTS) {
        return localStorage.getItem(this.getKey(component_name)) === "true";
    },
    set(component_name: DISMISSIBLE_COMPONENTS, hasDismissed: boolean) {
        localStorage.setItem(this.getKey(component_name), hasDismissed.toString());
    },
    delete(component_name: DISMISSIBLE_COMPONENTS) {
        localStorage.removeItem(this.getKey(component_name));
    },
};

const HasDismissedComponentWithinSession = {
    getKey: (component_name: DISMISSIBLE_COMPONENTS) => `--app:comp_name:${component_name}:dismissed`,
    get(component_name: DISMISSIBLE_COMPONENTS) {
        return sessionStorage.getItem(this.getKey(component_name)) === "true";
    },
    set(component_name: DISMISSIBLE_COMPONENTS, hasDismissed: boolean) {
        sessionStorage.setItem(this.getKey(component_name), hasDismissed.toString());
    },
    delete(component_name: DISMISSIBLE_COMPONENTS) {
        sessionStorage.removeItem(this.getKey(component_name));
    },
};

// This is used to track whether a user has dismissed a component
export enum HINT_TYPES {
    HAS_ACCOUNT = "has_account",
}
const HasHint = {
    getKey: (hint_type: HINT_TYPES) => `--app:hints:${hint_type}`,
    get(hint_type: HINT_TYPES) {
        return localStorage.getItem(this.getKey(hint_type)) === "true";
    },
    set(hint_type: HINT_TYPES, newState: boolean) {
        localStorage.setItem(this.getKey(hint_type), newState.toString());
    },
    delete(hint_type: HINT_TYPES) {
        localStorage.removeItem(this.getKey(hint_type));
    },
};

export enum SESSION_ERROR_TYPES {
    SUBSCRIPTION_SIGNUP = "subscription_signup",
}
const SessionError = {
    getKey: (error_type: SESSION_ERROR_TYPES) => `--app:session_errors:${error_type}`,
    get<T extends Record<string, unknown> | Array<Record<string, unknown>>>(error_type: SESSION_ERROR_TYPES) {
        try {
            const item = sessionStorage.getItem(this.getKey(error_type));
            return item ? (JSON.parse(item) as T extends Record<string, unknown> ? Partial<T> | null : T | null) : null;
        } catch (e) {
            console.error("Session error", e);
            return null;
        }
    },
    set<T extends Record<string, unknown> | Record<string, unknown>[]>(error_type: SESSION_ERROR_TYPES, errors: T) {
        sessionStorage.setItem(this.getKey(error_type), JSON.stringify(errors));
    },
    delete(error_type: SESSION_ERROR_TYPES) {
        sessionStorage.removeItem(this.getKey(error_type));
    },
};

const HasRecentlyRegisteredAccount = {
    getKey: () => "--app:recently_registered_account",
    get() {
        return !!localStorage.getItem(this.getKey());
    },
    set() {
        localStorage.setItem(this.getKey(), "true");
    },
    delete() {
        localStorage.removeItem(this.getKey());
    },
};

const HasDismissedOutage = {
    getKey: () => "--app:has_dismissed_outage",
    get() {
        return !!sessionStorage.getItem(this.getKey());
    },
    set() {
        sessionStorage.setItem(this.getKey(), "true");
    },
    delete() {
        sessionStorage.removeItem(this.getKey());
    },
};

const ChunkRetryStatus = {
    getKey: (chunkName: string) => "--app:chunk_retry_status:" + chunkName,
    get(chunkName: string) {
        return !!sessionStorage.getItem(this.getKey(chunkName));
    },
    set(chunkName: string) {
        sessionStorage.setItem(this.getKey(chunkName), "true");
    },
    delete(chunkName: string) {
        sessionStorage.removeItem(this.getKey(chunkName));
    },
};

export const StorageService = {
    LocationContext,
    ClientId,
    SessionToken,
    TypeaheadToken,
    HasPlayedIcyHomepageAnimation,
    HasShownAddFreePopup,
    HasShownAppOrExtensionPopup,
    HasShownStoryPageSignupDrawer,
    HasShownShopUsaSignupDrawer,
    HasShownCampaignPopup,
    DarkModePreference,
    CustomMessageDismissal,
    MarketingCampaignId,
    HomepageShopCTADismissal,
    HasLandedOnFreeSpoke,
    HasFreespokeExtensionEnabled,
    HasDismissedComponent,
    HasDismissedComponentWithinSession,
    HasShownPopupLocalStorage,
    HasHint,
    HasRecentlyRegisteredAccount,
    SessionError,
    HasDismissedOutage,
    ChunkRetryStatus,
};
