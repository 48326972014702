import { ComponentType, LazyExoticComponent, lazy } from "react";
import { StorageService }                           from "./storage";

// Adapted from https://www.codemzy.com/blog/fix-chunkloaderror-react

type LazyArg<T extends ComponentType<any>> = () => Promise<{
    default: T;
}>;
type LazyArgReturn<T extends ComponentType<any>> = Awaited<ReturnType<LazyArg<T>>>;

async function lazyRetry<T extends ComponentType<any>>(componentImport: LazyArg<T>, name: string): Promise<LazyArgReturn<T>> {
    const hasRefreshed = StorageService.ChunkRetryStatus.get(name);

    try {
        const comp = await componentImport();
        StorageService.ChunkRetryStatus.delete(name);
        return comp;
    } catch (err) {
        console.error(`Failed to load chunk ${name}.`, err);
        if (hasRefreshed) {
            console.error(`Failed to load chunk ${name} after retrying.`, err);
            throw err;
        }
        StorageService.ChunkRetryStatus.set(name);
        window.location.reload();
        return { default: (() => null) as unknown as T };
    }
}

export function lazyWithRetry<T extends ComponentType<any>>(componentImport: LazyArg<T>, name: string): LazyExoticComponent<T> {
    return lazy(() => lazyRetry(componentImport, name));
}
